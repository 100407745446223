import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const QuizDetailsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const quizDetails = location.state.quiz;
    const [showMore, setShowMore] = useState(false);

    const startQuiz = () => {
        navigate(`/quizzes/${quizDetails.id}`);
    };

    // Check if long description has more than 4 lines
    const isLongDescription = quizDetails.longDescription.split('\n').length > 4;

    return (
        <div className="container mx-auto px-6 py-8 lg:px-16">
            <div className="flex flex-col mt-4 mb-8 lg:flex-row lg:mb-8">
                <div className="flex-1 mb-8 lg:mb-0">
                    <h2 className="text-2xl font-bold mb-2">{quizDetails.name}</h2>
                    <p className="text-lg mb-4">{quizDetails.shortDescription}</p>
                    <button onClick={startQuiz} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Ok, lezggo 🚀
                    </button>
                </div>
                <div className="flex-1">
                    <img src={quizDetails.thumbnail} alt={quizDetails.name} className="w-full h-auto rounded-lg" />
                </div>
            </div>

            <div className="bg-gray-100 p-6 rounded-lg mb-8 flex flex-wrap justify-around">
            <div className="flex items-center mb-2 lg:mb-0">⏰ {quizDetails.timeLimit} Mins</div>
                <div className="flex items-center mb-2 lg:mb-0">🤔 {quizDetails.questions.length} Questions</div>
                <div className="flex items-center mb-2 lg:mb-0">{quizDetails.isFree ? '🎉 Free' : `💸 ${quizDetails.price} Taka`}</div>
                <div className="flex items-center">🧠 {quizDetails.difficulty}</div>
            </div>

            <div className="mb-4">
                <p className={showMore ? 'text-sm text-gray-600' : 'text-normal text-gray-600 line-clamp-4'}>
                    {quizDetails.longDescription}
                </p>
                {isLongDescription && (
                    <button onClick={() => setShowMore(!showMore)} className="text-blue-500 text-normal mt-2">
                        {showMore ? 'Show Less' : 'Show More'}
                    </button>
                )}
            </div>

            {/* Quiz rules section */}
        </div>
    );
};

export default QuizDetailsPage;