import { useEffect } from 'react';
import { motion } from 'framer-motion';
import { BookOpenIcon, BoltIcon, QuestionMarkCircleIcon, ArrowPathIcon } from '@heroicons/react/24/solid';

const sectionVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const Science = () => {
  useEffect(() => {
    document.title = 'The Science Behind Our Approach';
  }, []);

  return (
    <motion.div className="max-w-7xl mt-16 mx-auto py-12 px-4 sm:px-6 lg:px-8"
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      transition={{delay: 0.2, duration: 0.5}}
    >
      <div className="space-y-12 mx-auto" style={{ maxWidth: "800px" }}>
        <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
          <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">The Science Behind Our Approach</h2>
          <p className="text-xl text-gray-500">Our platform is grounded in the latest cognitive science research. Our techniques harness the power of Contextual Learning, Active Recall, the Testing Effect, and Spaced Repetition to provide an efficient and enjoyable learning experience. Here's how these techniques work.</p>
        </div>
        <ul className="space-y-8">
          <Section title="Contextual Learning" icon={BookOpenIcon} color="bg-red-500">
            <p>Words aren't just individual units of meaning - they're part of a larger context. When you learn new words through our engaging stories, you understand how they're used in real-life situations. This leads to deeper comprehension and more effective communication in English.</p>
          </Section>
          <Section title="Active Recall" icon={BoltIcon} color="bg-yellow-500">
            <p>Active Recall is a strategy that involves actively trying to remember information. This method is more effective than passive review because it strengthens your memory and improves long-term retention. In our app, the quizzes at the end of each story engage Active Recall, reinforcing your understanding of new words.</p>
          </Section>
          <Section title="Testing Effect" icon={QuestionMarkCircleIcon} color="bg-green-500">
            <p>The Testing Effect is a psychological phenomenon where trying to recall information leads to better long-term memory than merely studying. Our in-story quizzes exploit this effect, enhancing your learning and making sure you can confidently use the new words.</p>
          </Section>
          <Section title="Spaced Repetition" icon={ArrowPathIcon} color="bg-blue-500">
            <p>Spaced Repetition is a learning technique that involves increasing intervals of time between subsequent reviews of previously learned material to exploit the psychological spacing effect. Using a smart algorithm, our app schedules reviews at the optimal times to ensure maximum retention of the English words you've learned.</p>
          </Section>
        </ul>
        <References />
      </div>
    </motion.div>
  );
};

const Section = ({ title, children, icon: Icon, color }) => (
  <motion.li
    variants={sectionVariants}
    initial="hidden"
    animate="visible"
  >
    <div className="flex">
      <div className="flex-shrink-0">
        <div className={`flex items-center justify-center h-12 w-12 rounded-md ${color} text-white`}>
          <Icon className="h-6 w-6" aria-hidden="true" />
        </div>
      </div>
      <div className="ml-4">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        <p className="mt-2 text-base text-gray-500">{children}</p>
      </div>
    </div>
  </motion.li>
);

const References = () => (
  <div className="mt-10">
    <h3 className="text-lg leading-6 font-medium text-gray-900">References</h3>
    <ul className="list-disc list-inside text-base text-gray-500 mt-2">
      <li>
        Collins, A. M., & Loftus, E. F. (1975). A spreading-activation theory of semantic processing.
        <a href="https://psycnet.apa.org/doiLanding?doi=10.1037%2F0033-295X.82.6.407" className="underline text-blue-500 hover:text-blue-700 transition-colors duration-200">Link</a>
      </li>
      {/* ...other references */}
    </ul>
  </div>
);

export default Science;
