import React from 'react';

const FeedbackDisplay = ({ feedback, explanation, handleNextQuestion, showNext, isCorrect }) => {
  return (
    <div className="text-center mt-6">
      {feedback && (
        <>
          <p className={`text-lg mb-2 font-semibold ${isCorrect ? 'text-green-500' : 'text-red-500'}`}>
            {feedback}
          </p>
          <p className="text-md mb-4">{explanation}</p>
        </>
      )}
      {showNext && (
        <button
          className="mt-4 px-6 py-3 bg-purple-600 hover:bg-purple-800 text-white font-bold rounded-lg shadow transition duration-200 ease-in-out transform hover:-translate-y-1"
          onClick={handleNextQuestion}
        >
          Next Question
        </button>
      )}
    </div>
  );
};

export default FeedbackDisplay;