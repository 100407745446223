import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { quizService } from '../../services/quizService';

const QuizPage = () => {
    const { quizId } = useParams();
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [startTime, setStartTime] = useState(Date.now());

    useEffect(() => {
        quizService.fetchQuestionsForQuiz(quizId)
            .then(response => setQuestions(response.data))
            .catch(error => console.error("Error fetching questions", error));
    }, [quizId]);

    const handleAnswerSelect = (questionId, answerId) => {
        setSelectedAnswers({ ...selectedAnswers, [questionId]: answerId });
    };

    const submitQuiz = async () => {
        setIsLoading(true);
        const completionTime = Math.floor((Date.now() - startTime) / 1000); // Calculate time in seconds
        const formattedAnswers = Object.keys(selectedAnswers).map(questionId => ({
            answerId: selectedAnswers[questionId],
            questionId: parseInt(questionId)
        }));
    
        const payload = {
            answers: formattedAnswers,
            completionTime
        };
    
        try {
            const response = await quizService.submitQuizAnswers(quizId, payload);
            navigate(`/quiz-result/${response.data.attempt.userId}`); // Adjust as per your API response structure
        } catch (error) {
            console.error("Error submitting quiz", error);
        }
        setIsLoading(false);
    };            

    const nextQuestion = () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            submitQuiz(); // Submit quiz on last question
        }
    };

    if (isLoading || !questions.length) return <div>Loading...</div>;

    const { id, text, answers } = questions[currentQuestionIndex];
    const isLastQuestion = currentQuestionIndex === questions.length - 1;

    return (
        <div className="container mx-auto p-4">
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
                <h2 className="mb-4 text-xl font-bold">{text}</h2>
                <div className="mb-6">
                    {answers.map(answer => (
                        <button key={answer.id}
                            className={`w-full p-4 my-2 text-left ${selectedAnswers[id] === answer.id ? 'bg-blue-200' : 'bg-gray-200'} rounded-md`}
                            onClick={() => handleAnswerSelect(id, answer.id)}>
                            {answer.text}
                        </button>
                    ))}
                </div>
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={nextQuestion}>
                    {isLastQuestion ? 'Submit Quiz' : 'Next'}
                </button>
            </div>
        </div>
    );
};

export default QuizPage;