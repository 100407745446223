import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { quizAttemptService } from '../../services/quizAttemptService';

const ResultPage = () => {
  const { attemptId } = useParams();
  const [attemptDetails, setAttemptDetails] = useState(null);

  useEffect(() => {
    quizAttemptService.fetchAttemptDetails(attemptId)
      .then(response => setAttemptDetails(response.data))
      .catch(error => console.error("Error fetching attempt details", error));
  }, [attemptId]);

  if (!attemptDetails) return <div>Loading...</div>;

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Quiz Results</h2>
      {/* Display attempt details and results here */}
      <div>
        <p>Your Score: {attemptDetails.score}</p>
        <p>TimeTaken: {attemptDetails.completionTime} seconds</p>
        {/* Additional result details can be added here */}
      </div>
    </div>
  );
};

export default ResultPage;