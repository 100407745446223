import React, { useState } from 'react';

const SummaryDisplay = ({ score, totalQuestions }) => {
  const [name, setName] = useState('');

  const handleShare = () => {
    // Functionality to handle social media sharing
    // This can be implemented using social media APIs or sharing libraries
    console.log("Shared on social media");
  };

  return (
    <div className="text-center mt-10">
      <p className="text-2xl font-bold mb-6">Quiz Completed!</p>
      <p className="text-lg mb-4">Your score: {score}/{totalQuestions}</p>
      <input 
        type="text" 
        placeholder="Enter your name" 
        className="px-4 py-2 border border-gray-300 rounded-md mb-4"
        onChange={(e) => setName(e.target.value)}
      />
      {name && <p className="text-lg mb-4">Great job, {name}!</p>}
      <button 
        className="mt-4 px-6 py-3 bg-green-600 hover:bg-green-800 text-white font-bold rounded-lg shadow transition duration-200 ease-in-out"
        onClick={handleShare}
      >
        Share on Social Media
      </button>
    </div>
  );
};

export default SummaryDisplay;
