import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../services/axiosInstance';

// Async thunk for fetching all quizzes
export const fetchQuizzes = createAsyncThunk('quizzes/fetchQuizzes', async () => {
  const response = await axiosInstance.get('/quizzes');
  return response.data;
});

// Async thunk for fetching a single quiz by ID
export const fetchQuizById = createAsyncThunk('quizzes/fetchQuizById', async (quizId) => {
  const response = await axiosInstance.get(`/quizzes/${quizId}`);
  return response.data;
});

// Async thunk for submitting quiz answers and tracking progress
export const submitQuizAnswers = createAsyncThunk(
  'quizzes/submitQuizAnswers', 
  async ({ quizId, answers }) => {
    const response = await axiosInstance.post(`/quizzes/${quizId}/submit`, { answers });
    return response.data;
  }
);

const initialState = {
  quizzes: [],
  currentQuiz: null,
  quizSubmissionResult: null,
  loading: false,
  error: null
};

const quizSlice = createSlice({
    name: 'quizzes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchQuizzes.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchQuizzes.fulfilled, (state, action) => {
                state.quizzes = action.payload;
                state.loading = false;
            })
            .addCase(fetchQuizzes.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchQuizById.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchQuizById.fulfilled, (state, action) => {
                state.currentQuiz = action.payload;
                state.loading = false;
            })
            .addCase(fetchQuizById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(submitQuizAnswers.pending, (state) => {
                state.loading = true;
            })
            .addCase(submitQuizAnswers.fulfilled, (state, action) => {
                state.quizSubmissionResult = action.payload;
                state.loading = false;
            })
            .addCase(submitQuizAnswers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default quizSlice.reducer;