import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userService } from '../../services/userService';
import { updateUserData } from '../../store/userSlice';

const ProfilePage = () => {
    const user = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();
    const [editable, setEditable] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
    });

    useEffect(() => {
        if (user) {
            setFormData({
                firstName: user.firstName || '',
                lastName: user.lastName || '',
            });
        }
    }, [user]);

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const updatedUser = await userService.updateUserProfile(user.id, formData);
            dispatch(updateUserData(updatedUser.data));
            setEditable(false);
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const renderInitials = (firstName, lastName) => {
        return `${firstName.charAt(0)}${lastName.charAt(0)}`;
    };

    return (
        <div className="container mx-auto p-4">
            {user ? ( // Check if user object is available
                <>
                    <h2 className="text-2xl font-bold mb-4">User Profile</h2>
                    <div className="flex items-center mb-6">
                        {user.profilePhoto ? (
                            <img src={user.profilePhoto} alt="Profile" className="h-20 w-20 rounded-full" />
                        ) : (
                            <div className="h-20 w-20 rounded-full bg-blue-500 text-white flex items-center justify-center text-2xl">
                                {renderInitials(user.firstName, user.lastName)}
                            </div>
                        )}
                        <div className="ml-4">
                            <p className="text-lg">{`${user.firstName} ${user.lastName}`}</p>
                            <p className="text-sm text-gray-600">{user.email}</p>
                            <p className="text-sm text-gray-500">Joined: {formatDate(user.createdAt)}</p>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstName">First Name</label>
                            <input type="text" id="firstName" name="firstName"
                                value={formData.firstName} onChange={handleInputChange}
                                disabled={!editable} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight" />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastName">Last Name</label>
                            <input type="text" id="lastName" name="lastName"
                                value={formData.lastName} onChange={handleInputChange}
                                disabled={!editable} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight" />
                        </div>
                        <button type="button" onClick={() => setEditable(!editable)}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4">
                            {editable ? 'Cancel' : 'Edit'}
                        </button>
                        {editable && (
                            <button type="submit"
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                Save
                            </button>
                        )}
                    </form>
                </>
            ) : (
                <div>Loading user profile...</div> // Display a loading message or spinner
            )}
        </div>
    );
};

export default ProfilePage;