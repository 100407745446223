import React, { useState, useEffect } from 'react';
import { leaderboardService } from '../../services/leaderboardService';
import { UserCircleIcon } from '@heroicons/react/24/outline'; // Import the icon

const LeaderboardPage = () => {
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        leaderboardService.fetchLeaderboard()
            .then(response => {
                setLeaderboardData(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching leaderboard data", error);
                setIsLoading(false);
            });
    }, []);

    if (isLoading) return <div>Loading leaderboard...</div>;

    return (
        <div className="container mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">Leaderboard</h2>
            <div className="shadow-md rounded-lg overflow-hidden">
                {leaderboardData.map((entry, index) => (
                    <div key={entry.userId} className="flex items-center justify-between border-b p-4">
                        <div className="flex items-center">
                            <span className="mr-4">{index + 1}.</span>
                            {entry.user && entry.user.profilePhoto ? (
                                <img src={entry.user.profilePhoto} alt={`${entry.user.firstName} ${entry.user.lastName}`} className="h-10 w-10 rounded-full mr-4" />
                            ) : (
                                <UserCircleIcon className="h-10 w-10 text-gray-400 mr-4" /> // Default icon
                            )}
                            <span>{entry.user ? `${entry.user.firstName} ${entry.user.lastName}` : 'Unknown User'}</span>
                        </div>
                        <div>
                            <span className="font-semibold">{entry.highestScore}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LeaderboardPage;