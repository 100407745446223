import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../services/authService';
import { useDispatch } from 'react-redux';
import { login as loginAction } from '../store/userSlice';
import axiosInstance from '../services/axiosInstance';

const Login = () => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await authService.login(formData);
            localStorage.setItem('jwt', response.data.token);
            
            const userDataResponse = await axiosInstance.get('/current_user');

            if (userDataResponse.data.isLoggedIn) {
                dispatch(loginAction(userDataResponse.data.user));
                navigate('/');
            } else {
                console.error('Failed to fetch user data');
            }
        } catch (error) {
            console.error('Login error', error);
        }
    };

    return (
        <div className="container mx-auto p-4 max-w-md">
            <h2 className="text-2xl font-bold mb-6">Login</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                    <input type="email" name="email" id="email" required
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                        onChange={handleChange} />
                </div>
                <div>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                    <input type="password" name="password" id="password" required
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                        onChange={handleChange} />
                </div>
                <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Login
                </button>
                <p className="mt-4 text-center text-sm">
                    Don't have an account? <span className="text-blue-500 cursor-pointer" onClick={() => navigate('/register')}>Register</span>
                </p>
                <p className="mt-2 text-center text-sm">
                    <span className="text-blue-500 cursor-pointer" onClick={() => navigate('/forgot-password')}>Forgot Password?</span>
                </p>
            </form>
        </div>
    );
};

export default Login;