import { useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaFacebookF, FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';

const teamVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const Team = () => {
    useEffect(() => {
        document.title = 'Meet Our Team';
    }, []);

    return (
        <motion.div className="max-w-7xl mt-16 mx-auto py-12 px-4 sm:px-6 lg:px-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
        >
            <div className="space-y-12 mx-auto" style={{ maxWidth: "800px" }}>
                <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
                    <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Meet Our Team</h2>
                    <p className="text-xl text-gray-500">We believe that the power of learning English can unlock life-changing opportunities. Our team, comprised of educational experts and teachers, is dedicated to making this vision a reality.</p>
                </div>
                <Member name="Eshaya Hassan" role="Founder" img="https://www.dhakalawreview.org/wp-content/uploads/2016/11/14875019_1165535266855857_2090472704_n.jpg"
                    description="A law graduate turned ed-tech founder, Eshaya Hassan brings a unique perspective to EH Academy. As an online English teacher with over 900,000 followers on social media, Eshaya understands firsthand the potential of digital education. Her passion for teaching English and empowering others to unlock new opportunities drives our mission."
                    facebook="#" instagram="#"
                />
                <Member name="Dr. Tasnim Jara" role="Advisor of Cognitive Science" img="https://assets.shohay.health/uploads/2023/03/tasnim-jara.jpg"
                    description="Dr. Tasnim Jara wears many hats - she is a physician, an Associate fellow of Higher Education Academy (UK), a clinical teacher at the University of Cambridge, and a graduate of Oxford University, where she earned a distinction in Evidence-Based Medicine. With her deep understanding of cognitive science and learning theories, she is working with us to create an effective and scientifically grounded learning platform."
                    facebook="#" instagram="#" linkedin="#"
                />
                <Member name="Khaled Saifullah" role="Advisor" img="https://assets.shohay.health/uploads/2023/03/khaled-saifullah.jpg"
                    description="Dr. Tasnim Jara wears many hats - she is a physician, an Associate fellow of Higher Education Academy (UK), a clinical teacher at the University of Cambridge, and a graduate of Oxford University, where she earned a distinction in Evidence-Based Medicine. With her deep understanding of cognitive science and learning theories, she is working with us to create an effective and scientifically grounded learning platform."
                    facebook="#" instagram="#" linkedin="#"
                />
            </div>
        </motion.div>
    );
};

const Member = ({ name, role, img, description, facebook, instagram, linkedin, youtube, tiktok }) => (
    <motion.div
        variants={teamVariants}
        initial="hidden"
        animate="visible"
        className="mt-10 border-t border-gray-200 pt-10"
    >
        <div className="flex space-x-7">
            <div className="flex-shrink-0">
                <img src={img} alt={name} className="h-24 w-24 rounded-full max-w-full object-cover transform hover:scale-105 transition-all duration-200" />
            </div>
            <div className="min-w-0 flex-1">
                <h2 className="text-2xl font-bold text-gray-900">{name}</h2>
                <h3 className="text-xl text-gray-500">{role}</h3>
                <p className="mt-4 text-gray-500">{description}</p>
                <div className="mt-6 flex">
                    <a href={facebook} className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Facebook</span>
                        {<FaFacebookF />}
                    </a>
                    <a href={youtube} className="ml-6 text-gray-400 hover:text-gray-500">
                        <span className="sr-only">YouTube</span>
                        {<FaYoutube />}
                    </a>
                    <a href={instagram} className="ml-6 text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Instagram</span>
                        {<FaInstagram />}
                    </a>
                    <a href={tiktok} className="ml-6 text-gray-400 hover:text-gray-500">
                        <span className="sr-only">TikTok</span>
                        {<FaTiktok />}
                    </a>
                </div>
            </div>
        </div>
    </motion.div>
);

export default Team;