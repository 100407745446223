import axiosInstance from './axiosInstance';

const register = (userData) => {
    return axiosInstance.post('/auth/register', userData);
};

const login = (credentials) => {
    return axiosInstance.post('/auth/login', credentials);
};

const requestPasswordReset = (email) => {
    return axiosInstance.post('/auth/reset_password', { email });
};

const performPasswordReset = (resetData) => {
    return axiosInstance.post('/auth/perform_password_reset', resetData);
};

export const authService = {
    register,
    login,
    requestPasswordReset,
    performPasswordReset
};