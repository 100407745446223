import axiosInstance from './axiosInstance';

const fetchAttemptsForQuiz = (quizId) => {
  return axiosInstance.get(`/quizzes/${quizId}/attempts`);
};

const fetchAttemptDetails = (attemptId) => {
  return axiosInstance.get(`/quiz-attempts/${attemptId}`);
};

export const quizAttemptService = {
  fetchAttemptsForQuiz,
  fetchAttemptDetails,
};