import { configureStore } from '@reduxjs/toolkit';
import userReducer, { login } from './userSlice';
import quizReducer from './quizSlice';
import questionReducer from './questionSlice';
import orderReducer from './orderSlice';
import leaderboardReducer from './leaderboardSlice';
import axiosInstance from '../services/axiosInstance';
import quizAttemptReducer from './quizAttemptSlice';


const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (e) {
    console.error(e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const persistedState = loadFromLocalStorage();

export const store = configureStore({
  reducer: {
    user: userReducer,
    quizzes: quizReducer,
    questions: questionReducer,
    order: orderReducer,
    leaderboard: leaderboardReducer,
    quizAttempt: quizAttemptReducer,
  },
  preloadedState: persistedState,
});

store.subscribe(() => {
  saveToLocalStorage(store.getState());
});

axiosInstance.get('/current_user')
  .then(response => {
    if (response.data.isLoggedIn) {
      store.dispatch(login(response.data.user));
    }
  })
  .catch(error => console.error('Error fetching user data', error));