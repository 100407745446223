import React from 'react';

const QuestionDisplay = ({ question, handleOptionClick, userChoice, showNext }) => {
  return (
    <div className="mb-8">
      <p className="text-xl md:text-2xl font-semibold text-center mb-6">{question.sentence}</p>
      <div className="flex flex-wrap justify-center gap-4">
        {question.options.map((option, index) => (
          <button
            key={index}
            className={`px-6 py-3 text-lg font-semibold rounded-lg shadow-md transition duration-200 ease-in-out transform hover:-translate-y-1 ${userChoice === option ? 'bg-green-600 hover:bg-green-700' : 'bg-blue-600 hover:bg-blue-700'} text-white`}
            onClick={() => handleOptionClick(option)}
            disabled={showNext}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

export default QuestionDisplay;