import React from 'react';
import QuizCard from './QuizCard';

const QuizList = ({ quizzes }) => {
    return (
        <div className="container mx-auto px-4">
            <div className="flex flex-wrap -mx-3 lg:-mx-4">
                {quizzes.map(quiz => (
                    <div key={quiz.id} className="my-4 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                        <QuizCard quiz={quiz} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default QuizList;
