import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import NavBar from './components/NavBar';
import AuthHandler from './components/AuthHandler';
import GrammarGame from './components/GrammarDetective/GrammarGame';
import QuizPage from './components/quizzes/QuizPage';
import ResultPage from './components/quizzes/ResultPage';
import LeaderboardPage from './components/leaderboard/LeaderboardPage';
import ProfilePage from './components/user/ProfilePage';
import QuizDetailsPage from './components/quizzes/QuizDetailsPage';
import Register from './components/Register';
import Login from './components/Login';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ForgotPassword';
import Footer from './components/Footer';
import Team from './components/Team';
import Science from './components/Science';
import Subscription from './components/Subscription';


function App() {
  return (
    <Router>
      <AuthHandler />
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/GrammarDetective" element={<GrammarGame />} />
        <Route path="/quizzes/:quizId" element={<QuizPage />} />
        <Route path="/quiz-result/:attemptId" element={<ResultPage />} />
        <Route path="/leaderboard" element={<LeaderboardPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/quiz-details" element={<QuizDetailsPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/team" element={<Team />} />
        <Route path="/science" element={<Science />} />
        <Route path="/subscription" element={<Subscription />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;