import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../services/axiosInstance';

// Async thunk for fetching all attempts for a specific quiz
export const fetchAttemptsForQuiz = createAsyncThunk(
  'quizAttempts/fetchAttemptsForQuiz',
  async (quizId) => {
    const response = await axiosInstance.get(`/quizzes/${quizId}/attempts`);
    return response.data;
  }
);

// Async thunk for getting details of a specific quiz attempt
export const fetchAttemptDetails = createAsyncThunk(
  'quizAttempts/fetchAttemptDetails',
  async (attemptId) => {
    const response = await axiosInstance.get(`/quiz-attempts/${attemptId}`);
    return response.data;
  }
);

const initialState = {
  attempts: [],
  currentAttempt: null,
  loading: false,
  error: null
};

const quizAttemptSlice = createSlice({
    name: 'quizAttempts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAttemptsForQuiz.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAttemptsForQuiz.fulfilled, (state, action) => {
                state.attempts = action.payload;
                state.loading = false;
            })
            .addCase(fetchAttemptsForQuiz.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchAttemptDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAttemptDetails.fulfilled, (state, action) => {
                state.currentAttempt = action.payload;
                state.loading = false;
            })
            .addCase(fetchAttemptDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default quizAttemptSlice.reducer;