import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../services/axiosInstance';

// Async thunk for fetching all questions for a specific quiz
export const fetchQuestionsForQuiz = createAsyncThunk(
  'questions/fetchQuestionsForQuiz', 
  async (quizId) => {
    const response = await axiosInstance.get(`/quizzes/${quizId}/questions`);
    return response.data;
  }
);

const initialState = {
  questionsByQuiz: {}, // Stores questions indexed by quiz ID
  loading: false,
  error: null
};

const questionSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchQuestionsForQuiz.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchQuestionsForQuiz.fulfilled, (state, action) => {
                const { quizId, questions } = action.payload;
                state.questionsByQuiz[quizId] = questions;
                state.loading = false;
            })
            .addCase(fetchQuestionsForQuiz.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default questionSlice.reducer;