const questionsData = [
    {
      sentence: "He __ to school yesterday.",
      options: ["go", "went", "goes", "gone"],
      answer: "went",
      explanation: "The correct answer is 'went'. This sentence is in the simple past tense, which requires the past form of the verb 'go', which is 'went'."
    },
    {
      sentence: "They __ playing football when it started to rain.",
      options: ["have", "has", "were", "was"],
      answer: "were",
      explanation: "The correct answer is 'were'. The sentence describes an action in progress in the past, so we use the past continuous tense 'were playing'."
    },
    {
      sentence: "I __ seen that movie before.",
      options: ["have", "has", "had", "was"],
      answer: "have",
      explanation: "The correct answer is 'have'. This is an example of the present perfect tense, which is formed with 'have/has + past participle'."
    },
    {
      sentence: "If I __ enough money, I'd buy a new car.",
      options: ["have", "had", "will have", "has"],
      answer: "had",
      explanation: "The correct answer is 'had'. This sentence is a second conditional sentence expressing an unreal or hypothetical condition in the present or future."
    },
    {
      sentence: "She __ to the gym every morning.",
      options: ["goes", "go", "went", "going"],
      answer: "goes",
      explanation: "The correct answer is 'goes'. This sentence is in the simple present tense, used for habitual actions. The verb should agree with the third-person singular subject 'She'."
    },
    {
      sentence: "By the time we arrived, the lecture __ already started.",
      options: ["has", "have", "had", "was"],
      answer: "had",
      explanation: "The correct answer is 'had'. This is an example of the past perfect tense, used to indicate that an action was completed before another action in the past."
    },
    {
      sentence: "Can you __ me the book for a week?",
      options: ["lend", "borrow", "loan", "give"],
      answer: "lend",
      explanation: "The correct answer is 'lend'. 'Lend' means to give something to someone for a short period, expecting that they will give it back."
    }
  ];
  
  export default questionsData;  