import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../services/axiosInstance';

// Async thunk for fetching the leaderboard
export const fetchLeaderboard = createAsyncThunk('leaderboard/fetchLeaderboard', async () => {
  const response = await axiosInstance.get('/leaderboard');
  return response.data;
});

const initialState = {
  leaderboard: [],
  loading: false,
  error: null
};

const leaderboardSlice = createSlice({
    name: 'leaderboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLeaderboard.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchLeaderboard.fulfilled, (state, action) => {
                state.leaderboard = action.payload;
                state.loading = false;
            })
            .addCase(fetchLeaderboard.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default leaderboardSlice.reducer;