import React from 'react';
import { useNavigate } from 'react-router-dom';

const QuizCard = ({ quiz }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/quiz-details', { state: { quiz: quiz } }); // Navigate with quiz data
    };

    return (
        <div className="max-w-sm rounded-md overflow-hidden border shadow-lg hover:shadow-2xl transition duration-300 ease-in-out cursor-pointer" onClick={handleClick}>
            <img className="w-full" src={quiz.thumbnail} alt={`Thumbnail for ${quiz.name}`} />
            <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">{quiz.name}</div>
                <p className="text-gray-700 text-base">
                    {quiz.shortDescription}
                </p>
            </div>
            <div className="px-6 pt-4 pb-2">
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">{quiz.difficulty}</span>
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">{quiz.topic}</span>
                {quiz.price > 0 && <span className="inline-block bg-green-200 rounded-full px-3 py-1 text-sm font-semibold text-green-800 mr-2 mb-2">৳{quiz.price}</span>}
            </div>
        </div>
    );
};

export default QuizCard;
