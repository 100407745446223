import React from 'react';
import { Link } from 'react-router-dom';
import { CalendarIcon, CheckCircleIcon } from '@heroicons/react/24/outline';

const SubscriptionPage = () => {
  return (
    <div className="container mx-auto max-w-3xl mt-16 mb-16 px-4 sm:px-0">
      <h1 className="text-2xl font-semibold mb-10 text-center">
        You need to subscribe to access this content
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 mb-10 mt-6">
        <div className="bg-gray-100 shadow-sm rounded-lg p-6 flex flex-col items-center space-y-4">
          <CalendarIcon className="h-12 w-12 text-gray-500" />
          <h2 className="text-xl font-semibold mb-2 text-gray-500">3-Month Subscription</h2>
          <p className="text-base font-medium text-gray-600 text-center">This is a good start, but consider our 6-month plan for better value.</p>
        </div>
        <div className="bg-blue-100 shadow-lg rounded-lg p-6 flex flex-col items-center space-y-4 relative">
          <div className="absolute top-0 right-0 bg-blue-500 text-white rounded-bl rounded-tl px-2 py-1 text-xs">Most Popular</div>
          <CheckCircleIcon className="h-12 w-12 text-blue-500" />
          <h2 className="text-xl font-semibold mb-2">6-Month Subscription</h2>
          <p className="text-base font-medium text-gray-700 text-center">Get 6 months of access to our premium content. Save 20% with this option.</p>
        </div>
      </div>
      <Link
        to="/login"
        className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-6 mx-auto text-center w-full sm:w-auto"
      >
        Log In / Register
      </Link>
    </div>
  );
};

export default SubscriptionPage;
