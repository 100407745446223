import questionsData from './questionsData';
import React, { useState } from 'react';
import QuestionDisplay from './QuestionDisplay';
import FeedbackDisplay from './FeedbackDisplay';
import SummaryDisplay from './SummaryDisplay';

const GrammarGame = () => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [score, setScore] = useState(0);
    const [userChoice, setUserChoice] = useState("");
    const [feedback, setFeedback] = useState("");
    const [showNext, setShowNext] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);

    const handleOptionClick = (option) => {
        const correct = option === questionsData[currentQuestion].answer;
        setIsCorrect(correct);

        setUserChoice(option);
        setShowNext(true);

        if (correct) {
            setFeedback("Correct! Great job.");
            setScore(score + 1);
        } else {
            setFeedback("Not quite right, try again!");
        }
    };

    const handleNextQuestion = () => {
        if (currentQuestion < questionsData.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
            setUserChoice("");
            setFeedback("");
            setShowNext(false);
        } else {
            setShowSummary(true);
            setFeedback(`Quiz Completed! Your score: ${score}/${questionsData.length}`);
        }
    };

    const [showSummary, setShowSummary] = useState(false);

    return (
        <div className="container mx-auto p-4 mt-8">
            <h1 className="text-2xl font-bold text-center mb-4">Grammar Detective</h1>
            {!showSummary ? (
                <>
                    <QuestionDisplay
                        question={questionsData[currentQuestion]}
                        handleOptionClick={handleOptionClick}
                        userChoice={userChoice}
                        showNext={showNext}
                    />
                    <FeedbackDisplay
                        feedback={feedback}
                        explanation={questionsData[currentQuestion].explanation}
                        handleNextQuestion={handleNextQuestion}
                        showNext={showNext}
                        isCorrect={isCorrect}
                    />
                </>
            ) : (
                <SummaryDisplay
                    score={score}
                    totalQuestions={questionsData.length}
                />
            )}
        </div>
    );
};

export default GrammarGame;