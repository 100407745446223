import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../store/userSlice';
import axiosInstance from '../services/axiosInstance';
import { ArrowLeftStartOnRectangleIcon } from '@heroicons/react/24/outline';

const LogoutButton = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    axiosInstance.get('/logout')
      .then(() => {
        dispatch(logout());
        localStorage.removeItem('jwt');
        window.location.href = '/';
      })
      .catch(error => console.error('Error during logout', error));
  };

  return (
    <div className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer" onClick={handleLogout}>
      <ArrowLeftStartOnRectangleIcon className="h-5 w-5 mr-3" />Logout
    </div>
  );
};

export default LogoutButton;