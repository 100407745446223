import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { quizService } from '../services/quizService';
import QuizList from './quizzes/QuizList';

const HomePage = () => {
  const [quizzes, setQuizzes] = useState([]);

  useEffect(() => {
    quizService.fetchQuizzes()
      .then(response => {
        setQuizzes(response.data);
      })
      .catch(error => {
        console.error("Error fetching quizzes", error);
      });
  }, []);

  return (
    <div className="container mx-auto p-4 mt-16">
      <header className="text-center mb-16">
        <h1 className="text-4xl lg:text-4xl font-bold mb-2">Learn with Eshaya</h1>
        <p className="text-lg mb-4">Master English, Unlock Opportunities</p>
        <div className="flex justify-center gap-6 mb-12">
          <a href="https://www.facebook.com/EshayaHassan" aria-label="Facebook" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} className="h-5 w-5 text-blue-600" /></a>
          <a href="https://www.instagram.com/eshayahassan" aria-label="Instagram" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} className="h-5 w-5 text-pink-600" /></a>
          <a href="https://tiktok.com/@eshayahassan" aria-label="TikTok" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTiktok} className="h-5 w-5 text-black" /></a>
          <a href="https://www.youtube.com/eshayahassan" aria-label="YouTube" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} className="h-5 w-5 text-red-600" /></a>
        </div>
      </header>
      
      <QuizList quizzes={quizzes} /> {/* Quiz list */}

    </div>
  );
};

export default HomePage;