import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { login, logout } from '../store/userSlice';
import axiosInstance from '../services/axiosInstance';

const AuthHandler = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');

    if (token) {
      localStorage.setItem('jwt', token);
      axiosInstance.get('/current_user')
        .then(response => {
          console.log('Current user response:', response.data);
          if (response.data.isLoggedIn) {
            dispatch(login(response.data.user));
          } else {
            dispatch(logout());
          }
        })
        .catch(error => console.error('Error fetching user data', error));
      // Clean up URL
      window.history.pushState({}, document.title, "/");
    } else {
      // Check for JWT token in local storage
      const storedToken = localStorage.getItem('jwt');
      if (storedToken) {
        axiosInstance.get('/current_user')
          .then(response => {
            if (response.data.isLoggedIn) {
              dispatch(login(response.data.user));
            } else {
              dispatch(logout());
              localStorage.removeItem('jwt');
            }
          })
          .catch(() => {
            dispatch(logout());
            localStorage.removeItem('jwt');
          });
      }
    }
  }, [dispatch]);

  return null; // This component does not render anything
};

export default AuthHandler;