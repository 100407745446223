import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../services/authService';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage('');
        setErrorMessage('');
        try {
            await authService.requestPasswordReset({ email });
            setSuccessMessage('Password reset link sent. Please check your email.');
            setTimeout(() => navigate('/reset-password'), 3000);
        } catch (error) {
            setErrorMessage('Error requesting password reset. Please try again.');
            console.error('Error requesting password reset', error);
        }
    };

    return (
        <div className="container mx-auto p-16 max-w-md">
            <h2 className="text-2xl font-semibold mb-6">Forgot Password</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                    <input type="email" name="email" id="email" required
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                        onChange={handleChange} />
                </div>
                <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Request Reset Link
                </button>
                {successMessage && (
                    <div className="mt-4 flex items-center text-green-600 bg-green-100 border border-green-200 p-4 rounded-md">
                        <CheckCircleIcon className="h-6 w-6 flex-shrink-0 mr-2" />
                        <p className="flex-grow">{successMessage}</p>
                    </div>
                )}
                {errorMessage && (
                    <div className="mt-4 flex items-center text-red-600 bg-red-100 border border-red-200 p-4 rounded-md">
                        <ExclamationCircleIcon className="h-6 w-6 flex-shrink-0 mr-2" />
                        <p className="flex-grow">{errorMessage}</p>
                    </div>
                )}
                <p className="mt-4 text-center text-sm">
                    Remembered your password? <span className="text-blue-500 cursor-pointer" onClick={() => navigate('/login')}>Login</span>
                </p>
            </form>
        </div>
    );
};

export default ForgotPassword;