import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../services/authService';

const ResetPassword = () => {
    const [resetCode, setResetCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'resetCode') {
            setResetCode(value);
        } else if (name === 'newPassword') {
            setNewPassword(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await authService.performPasswordReset({ code: resetCode, newPassword });
            setSuccessMessage(response.data.message);
            setTimeout(() => navigate('/login'), 3000); // Redirect to login after a delay
        } catch (error) {
            setErrorMessage(error.response?.data?.message || 'Error resetting password');
        }
    };

    return (
        <div className="container mx-auto p-4 max-w-md">
            <h2 className="text-2xl font-bold mb-6">Reset Password</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="resetCode" className="block text-sm font-medium text-gray-700">Reset Code</label>
                    <input type="text" name="resetCode" id="resetCode" required
                           className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                           value={resetCode} onChange={handleChange} />
                </div>
                <div>
                    <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">New Password</label>
                    <input type="password" name="newPassword" id="newPassword" required
                           className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                           value={newPassword} onChange={handleChange} />
                </div>
                {errorMessage && <div className="mt-4 text-red-500">{errorMessage}</div>}
                {successMessage && <div className="mt-4 text-green-500">{successMessage}</div>}
                <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Reset Password
                </button>
                <p className="mt-4 text-center text-sm">
                    <span className="text-blue-500 cursor-pointer" onClick={() => navigate('/login')}>Back to Login</span>
                </p>
            </form>
        </div>
    );
};

export default ResetPassword;