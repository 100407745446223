import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer className="bg-gray-200 text-center text-white py-12 mt-10">
            <div className="container mx-auto px-4 md:px-0">
                <div className="flex flex-wrap justify-center mb-10 space-x-4 md:space-x-8">
                    <Link to="/" className="text-base font-normal py-2 px-4 rounded text-gray-600 hover:text-white">
                        Home
                    </Link>
                    <Link to="/courses" className="text-base font-normal py-2 px-4 rounded text-gray-600 hover:text-white">
                        Courses
                    </Link>
                    <Link to="/science" className="text-base font-normal py-2 px-4 rounded text-gray-600 hover:text-white">
                        Science
                    </Link>
                    <Link to="/subscription" className="text-base font-normal py-2 px-4 rounded text-gray-600 hover:text-white">
                        Subscription
                    </Link>
                    <Link to="/team" className="text-base font-normal py-2 px-4 rounded text-gray-600 hover:text-white">
                        Team
                    </Link>
                </div>
                <div className="flex justify-center mb-10 space-x-4 md:space-x-8">
                    <a href="https://www.facebook.com/EshayaHassan" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faFacebook} className="h-5 w-5 text-blue-600" />
                    </a>
                    <a href="https://www.instagram.com/eshayahassan/" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faInstagram} className="h-5 w-5 text-pink-600" />
                    </a>
                    <a href="https://www.tiktok.com/@eshayahassan" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faTiktok} className="h-5 w-5 text-black" />
                    </a>
                    <a href="https://www.youtube.com/@EshayaHassan" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faYoutube} className="h-5 w-5 text-red-600" />
                    </a>
                </div>
                <p className="text-gray-800 text-sm font-light">© 2023 by Eshaya Hassan. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;