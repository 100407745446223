import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../services/authService';
import { useDispatch } from 'react-redux';
import { login as loginAction } from '../store/userSlice';
import axiosInstance from '../services/axiosInstance';

const Register = () => {
    const [formData, setFormData] = useState({ email: '', password: '', firstName: '', lastName: '' });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await authService.register(formData);
            localStorage.setItem('jwt', response.data.token);

            const userDataResponse = await axiosInstance.get('/current_user');

            if (userDataResponse.data.isLoggedIn) {
                dispatch(loginAction(userDataResponse.data.user));
                // Redirect to home page or dashboard
                navigate('/');
            } else {
                console.error('Failed to fetch user data');
            }
        } catch (error) {
            console.error('Registration error', error);
        }
    };

    return (
        <div className="container mx-auto p-4 max-w-md">
            <h2 className="text-2xl font-bold mb-6">Register</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
                    <input type="text" name="firstName" id="firstName" required
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                        onChange={handleChange} />
                </div>
                <div>
                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
                    <input type="text" name="lastName" id="lastName" required
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                        onChange={handleChange} />
                </div>
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                    <input type="email" name="email" id="email" required
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                        onChange={handleChange} />
                </div>
                <div>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                    <input type="password" name="password" id="password" required
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                        onChange={handleChange} />
                </div>
                <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Register
                </button>
                <p className="mt-4 text-center text-sm">
                    Already have an account? <span className="text-blue-500 cursor-pointer" onClick={() => navigate('/login')}>Login</span>
                </p>
            </form>
        </div>
    );
};

export default Register;