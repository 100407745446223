import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, Link } from 'react-router-dom';
import { HomeIcon as HomeOutline, ChartBarIcon as ChartBarOutline, UserCircleIcon, UserIcon } from '@heroicons/react/24/outline';
import { HomeIcon as HomeSolid, ChartBarIcon as ChartBarSolid } from '@heroicons/react/24/solid';
import LogoutButton from './LogoutButton';

const NavBar = () => {
  const user = useSelector((state) => state.user.userData);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();

  const getIcon = (path, IconOutline, IconSolid) => {
    return location.pathname === path ? <IconSolid className="h-5 w-5" /> : <IconOutline className="h-5 w-5" />;
  };

  return (
    <nav className="bg-white shadow px-4 sm:px-6 lg:px-16">
      <div className="flex justify-between h-20 items-center">
        <div className="text-lg font-semibold hidden sm:block">Learn with Eshaya</div>

        <div className="flex-1 flex justify-start sm:justify-center items-center sm:order-2">
          <NavLink to="/" className="flex flex-col items-center mx-4">
            {getIcon('/', HomeOutline, HomeSolid)}
            <span className="text-xs">Home</span>
          </NavLink>
          <NavLink to="/leaderboard" className="flex flex-col items-center mx-4">
            {getIcon('/leaderboard', ChartBarOutline, ChartBarSolid)}
            <span className="text-xs">Leaderboard</span>
          </NavLink>
        </div>

        <div className="sm:order-3 relative" onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
          <div className="cursor-pointer">
            {isLoggedIn ? (
              <>
                {user && user.profilePhoto ? (
                  <img src={user.profilePhoto} alt="Profile" className="h-8 w-8 rounded-full" />
                ) : (
                  <UserCircleIcon className="h-8 w-8 text-gray-500" />
                )}

                {showDropdown && (
                  <div className="absolute right-0 py-2 w-44 bg-white rounded-md shadow-lg border z-20">
                    <NavLink to="/profile" className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      <UserIcon className="h-5 w-5 mr-3" />Profile
                    </NavLink>
                    <LogoutButton />
                  </div>
                )}
              </>
            ) : (
              <Link to="/login" className="text-white px-4 py-2 rounded bg-gradient-to-r from-blue-500 to-blue-500 hover:from-blue-600 hover:to-blue-600">
                Login
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;