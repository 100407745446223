import axiosInstance from './axiosInstance';

const updateUserProfile = (userId, data) => {
  return axiosInstance.put(`/users/${userId}`, data);
};

export const userService = {
  updateUserProfile,
};

