import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'development' 
    ? 'http://localhost:3001/api' 
    : 'https://eshayahassan.com/api';

const axiosInstance = axios.create({
    baseURL,
    withCredentials: true
});

// Interceptor to add JWT token to request headers
axiosInstance.interceptors.request.use(config => {
    const token = localStorage.getItem('jwt');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

export default axiosInstance;
