import axiosInstance from './axiosInstance';

const fetchQuizzes = () => {
    return axiosInstance.get('/quizzes');
};

const fetchQuizById = (quizId) => {
    return axiosInstance.get(`/quizzes/${quizId}`);
};

const submitQuizAnswers = (quizId, data) => {
    return axiosInstance.post(`/quizzes/${quizId}/submit`, data);
};

const fetchRandomizedQuestions = (quizId) => {
    return axiosInstance.get(`/quizzes/${quizId}/random-questions`);
};

const fetchQuestionsForQuiz = (quizId) => {
    return axiosInstance.get(`/quizzes/${quizId}/questions`);
};

export const quizService = {
    fetchQuizzes,
    fetchQuizById,
    submitQuizAnswers,
    fetchRandomizedQuestions,
    fetchQuestionsForQuiz,
};
